<template>
    <modal
        title="Create Event Type"
        @close="onClose"
    >
        <form
            novalidate
            @submit.prevent="onSubmit"
        >
            <form-row>
                <form-input
                    id="event-kind-name"
                    v-model="name"
                    label="Name"
                    type="text"
                    v-bind="nameAttrs"
                    :icon="['fad', 'filters']"
                    :errors="errors.name"
                    autocomplete="off"
                />
            </form-row>
        </form>

        <template #footer>
            <styled-button
                :icon="['fad', 'save']"
                :loading="loading"
                :disabled="!meta.valid"
                @click.prevent="onSubmit"
            >
                Create Event Type
            </styled-button>
        </template>
    </modal>
</template>

<script setup lang="ts">
    import { useForm } from 'vee-validate';
    import { toTypedSchema } from '@vee-validate/yup';
    import * as yup from 'yup';
    import { library } from '@fortawesome/fontawesome-svg-core';
    import { faSave, faFilters } from '@fortawesome/pro-duotone-svg-icons';
    import {
        CREATE_EVENT_KIND_MUTATION,
        type CreateEventKindData,
        type CreateEventKindVariables,
    } from '~/graphql/mutations/eventKind';
    import type { EventKindType, OperationInfo } from '~/generated/types';

    library.add(faSave, faFilters);

    export interface Props {
        defaultName?: string;
    }

    const props = withDefaults(defineProps<Props>(), {
        defaultName: '',
    });

    const emits = defineEmits<{
        close: [void];
        result: [{ id: string, text: string }];
    }>();

    const onClose = () => emits('close');

    const { meta, errors, defineField, handleSubmit } = useForm({
        validationSchema: toTypedSchema(
            yup.object({
                name: yup.string().max(255).required().label('Name').default(props.defaultName),
            }),
        ),
    });

    const [ name, nameAttrs ] = defineField('name');

    const { mutate, loading } = useMutation<CreateEventKindData, CreateEventKindVariables>(CREATE_EVENT_KIND_MUTATION);

    const onSubmit = handleSubmit(async values => {
        if (loading.value) {
            return;
        }

        const response = await mutate({
            data: values,
        });

        const data = response?.data?.createEventKind as EventKindType;
        const info = response?.data?.createEventKind as OperationInfo;

        if (!data) {
            console.log(info);
            return;
        }

        emits('result', {
            id: data.id,
            text: data.name,
        });
    });
</script>
