<template>
    <modal
        :title="props.title"
        :padding="false"
        @close="onClose"
    >
        <section class="px-4 lg:px-6 pt-6 pb-3 sticky top-0 bg-body dark:bg-gray-900 z-1">
            <form-row no-collapse>
                <form-input
                    id="filter-search"
                    v-model="filterSearch"
                    type="text"
                    placeholder="Search"
                />
            </form-row>
        </section>

        <section class="px-4 lg:px-6 pb-6 lg:pb-8">
            <ul>
                <li
                    v-for="{ node } in categories?.edges.filter(edge => edge.node.resources.length > 0) || []"
                    :key="node.id"
                    class="mb-2 last:mb-0"
                >
                    <h3 class="bg-body dark:bg-gray-900 text-sm font-semibold text-gray-500 dark:text-gray-400 py-1 sticky top-20">{{ node.name }}</h3>

                    <ul>
                        <li
                            v-for="resource in node.resources"
                            :key="resource.id"
                            class="mb-2 last:mb-0"
                        >
                            <button
                                type="button"
                                class="flex items-center w-full text-left p-4 rounded-lg bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700 disabled:opacity-50 disabled:pointer-events-none"
                                :disabled="disabledIds.includes(resource.id)"
                                @click.prevent="emits('result', resource)"
                            >
                                <span class="flex-1">
                                    <span class="block">{{ resource.name }}</span>
                                    <span
                                        v-if="resource.description"
                                        class="block text-xs"
                                    >{{ resource.description }}</span>
                                </span>

                                <pill
                                    v-if="!disabledIds.includes(resource.id) && resource.resourceAllocations.length > 0"
                                    severity="warning"
                                >
                                    Schedule Conflict
                                </pill>
                            </button>
                        </li>
                    </ul>
                </li>
            </ul>
        </section>
    </modal>
</template>

<script setup lang="ts">
    import { createListFactory } from '~/factories/list';

    import type {
        ResourceAllocationFilter,
        ResourceCategoryTypeConnection,
        ResourceFilter,
        ResourceType,
    } from '~/generated/types';
    import {
        GET_RESOURCE_CATEGORIES_QUERY,
        type GetResourceCategoriesData,
        type GetResourceCategoriesVariables,
    } from '~/graphql/queries/resources';

    export interface Props {
        title: string;
        disabledIds?: string[];
        startDate?: Date;
        endDate?: Date;
    }

    const props = withDefaults(defineProps<Props>(), {
        disabledIds: () => ([]),
        startDate: undefined,
        endDate: undefined,
    });

    const emits = defineEmits<{
        close: [void];
        result: [ResourceType];
    }>();

    const filterSearch = ref<string>('');

    const onClose = () => emits('close');

    const variables = computed<GetResourceCategoriesVariables>(() => {
        const resourceFilter: ResourceFilter = {
            name: {
                iContains: filterSearch.value,
            },
        };

        const allocationFilter: ResourceAllocationFilter = {};

        if (props.startDate && props.endDate) {
            allocationFilter.event = {
                startTime: {
                    lte: props.endDate,
                },
                endTime: {
                    gte: props.startDate,
                },
            };
        }

        return {
            first: PAGE_SIZE,
            resourceFilter,
            allocationFilter,
            includeAllocations: true,
        };
    });

    const { data: categories, result, loading, loadMoreResults, hasMore } = createListFactory<GetResourceCategoriesData, ResourceCategoryTypeConnection, GetResourceCategoriesVariables>(GET_RESOURCE_CATEGORIES_QUERY, variables, result => {
        return result?.resourceCategories;
    });
</script>
