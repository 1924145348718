<template>
    <ul>
        <li
            v-for="group in groupsWithPermission"
            :key="group.id"
            class="nav__group"
        >
            <h3 class="text-gray-700 dark:text-gray-300 text-sm font-medium mb-2 px-4">{{ group.label }}</h3>

            <ul>
                <li
                    v-for="node in group.nodes?.filter(n => n.hasPermission !== false)"
                    :key="node.id"
                    class="nav__item"
                >
                    <nuxt-link
                        v-if="node.path"
                        :to="node.path"
                        class="nav__link"
                        active-class="nav__link--active"
                    >
                        <font-awesome-icon
                            :icon="node.icon"
                            class="nav__icon"
                        />
                        <span>{{ node.label }}</span>
                    </nuxt-link>

                    <button
                        v-else-if="(node.children?.length ?? 0) > 0 && node.children?.some(n => n.hasPermission !== false && n.nodes?.some(n => n.hasPermission !== false))"
                        type="button"
                        class="nav__link"
                        @click.prevent="emits('nodeSelected', node)"
                    >
                        <font-awesome-icon
                            :icon="node.icon"
                            class="nav__icon"
                        />
                        <span class="flex-1">{{ node.label }}</span>

                        <font-awesome-icon
                            :icon="['fas', 'chevron-right']"
                            class="block w-4 h-4 ml-2"
                        />
                    </button>
                </li>
            </ul>
        </li>
    </ul>
</template>

<script setup lang="ts">
    import { library } from '@fortawesome/fontawesome-svg-core';
    import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
    import type { NavigationGroup, NavigationNode } from '~/types';

    library.add(faChevronRight);

    export interface Props {
        groups: NavigationGroup[];
    }

    const props = withDefaults(defineProps<Props>(), {});

    const emits = defineEmits<{
        nodeSelected: [NavigationNode]
    }>();

    const groupsWithPermission = computed(() => props.groups.filter(group => {
        return group.hasPermission !== false &&
            group.nodes?.some(node => {
                return node.hasPermission !== false &&
                    !node.children?.some(child => child.hasPermission !== false);
            });
    }));
</script>
