<template>
    <main class="default-layout">
        <div
            ref="nav"
            class="default-layout__nav nav"
            :class="{ 'nav--open': isMenuOpen }"
        >
            <div class="sticky flex items-center bg-gray-100 dark:bg-gray-800 top-0 p-4">
                <div class="flex-1">
                    <nuxt-link
                        to="/"
                        class="flex justify-start"
                    >
                        <organisation-logo logo-class="w-auto max-h-8" />
                    </nuxt-link>
                </div>

                <button
                    type="button"
                    class="relative text-xl p-3 leading-none bg-gray-200 dark:bg-gray-900 rounded-lg mr-2 min-w-[44.5px] ring-primary-600 focus:ring-2"
                    @click.prevent="onToggleDarkMode"
                >
                    <font-awesome-icon
                        v-if="systemConfig.darkMode"
                        :icon="['fad', 'moon']"
                        class="opacity-60"
                    />

                    <font-awesome-icon
                        v-else
                        :icon="['fad', 'sun']"
                        class="text-yellow-600 opacity-90"
                    />
                </button>

                <notifications />
            </div>

            <div
                ref="navItems"
                class="nav__items"
            >
                <div>
                    <navigation-items
                        :groups="navigation.filter(g => g.hasPermission !== false && g.nodes?.some(n => n.hasPermission !== false))"
                        class="flex-1 w-full"
                        @node-selected="onSelectNode"
                    />
                </div>

                <div v-if="activeNode">
                    <div class="mb-4">
                        <button
                            type="button"
                            class="nav__link"
                            @click.prevent="onBack"
                        >
                            <font-awesome-icon
                                :icon="['fad', 'left']"
                                class="nav__icon"
                            />
                            <span>Back</span>
                        </button>
                    </div>

                    <navigation-items
                        :groups="activeNode.children"
                        class="flex-1 w-full"
                    />
                </div>
            </div>

            <div class="sticky bottom-0 bg-gray-100 dark:bg-gray-800 py-2 px-4">
                <ul class="flex w-full">
                    <li class="flex-1">
                        <button
                            class="nav__link"
                            @click="onLogout"
                        >
                            <font-awesome-icon
                                :icon="['fad', 'arrow-left-from-bracket']"
                                class="nav__icon"
                            />
                            <span>Logout</span>
                        </button>
                    </li>

                    <li>
                        <nuxt-link
                            to="/account"
                            class="nav__link"
                            active-class="nav__link--active"
                        >
                            <font-awesome-icon
                                :icon="['fad', 'user']"
                                class="nav__icon"
                            />
                            <span>Account</span>
                        </nuxt-link>
                    </li>
                </ul>

                <p class="text-xs px-4 mt-3 text-black/50 dark:text-white/50">Version <span class="font-mono">{{ runtimeConfig.public.apolloVersion }}</span></p>
            </div>
        </div>

        <transition name="fade">
            <div
                v-if="isMenuOpen"
                class="nav-overlay"
                @click.prevent="isMenuOpen = false"
            />
        </transition>

        <div class="default-layout__wrapper">
            <div class="default-layout__window">
                <slot />
            </div>
        </div>
    </main>

    <modal-choose-user
        v-if="modalState.active === ModalType.CHOOSE_USER"
        :title="modalState.title || ''"
        v-bind="modalState.params"
        @close="onModalClose"
        @result="onModalResult"
    />

    <modal-create-client
        v-if="modalState.active === ModalType.CREATE_CLIENT"
        v-bind="modalState.params"
        @close="onModalClose"
        @result="onModalResult"
    />

    <modal-create-event-kind
        v-if="modalState.active === ModalType.CREATE_EVENT_KIND"
        v-bind="modalState.params"
        @close="onModalClose"
        @result="onModalResult"
    />

    <modal-choose-resource
        v-if="modalState.active === ModalType.CHOOSE_RESOURCE"
        :title="modalState.title || ''"
        v-bind="modalState.params"
        @close="onModalClose"
        @result="onModalResult"
    />

    <modal-text-input
        v-if="modalState.active === ModalType.TEXT_INPUT"
        :title="modalState.title || ''"
        v-bind="modalState.params"
        @close="onModalClose"
        @result="onModalResult"
    />
</template>

<script setup lang="ts">
    import { library } from '@fortawesome/fontawesome-svg-core';
    import {
        faArrowLeftFromBracket,
        faBadgeCheck,
        faBandage,
        faBuilding,
        faCalendarCheck,
        faCalendarDays,
        faCar,
        faClock,
        faCode,
        faCog,
        faComments,
        faFaceThinking,
        faFileCirclePlus,
        faFiles,
        faFilters,
        faGraduationCap,
        faGrid,
        faHospital,
        faIdCard,
        faLeft,
        faList,
        faListCheck,
        faMap,
        faMedal,
        faMoon,
        faPeopleSimple,
        faPills,
        faStethoscope,
        faSun,
        faTentDoublePeak,
        faUser,
        faUsers,
    } from '@fortawesome/pro-duotone-svg-icons';
    import { faSlack } from '@fortawesome/free-brands-svg-icons';
    import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
    import type { NavigationGroup, NavigationNode } from '~/types';
    import type { UserType } from '~/generated/types';
    import { ModalType } from '~/composables/modals';

    library.add(
        faArrowLeftFromBracket,
        faBadgeCheck,
        faBandage,
        faBuilding,
        faCalendarCheck,
        faCalendarDays,
        faCar,
        faClock,
        faCode,
        faCog,
        faComments,
        faFaceThinking,
        faFileCirclePlus,
        faFiles,
        faFilters,
        faGraduationCap,
        faGrid,
        faHospital,
        faIdCard,
        faLeft,
        faList,
        faListCheck,
        faMap,
        faMedal,
        faMoon,
        faPeopleSimple,
        faPills,
        faStethoscope,
        faSlack,
        faSun,
        faTentDoublePeak,
        faUser,
        faUsers,
    );

    const { signOut } = useAuth();
    const { data } = useAuthState();
    const { $listen, $event } = useNuxtApp();
    const route = useRoute();
    const runtimeConfig = useRuntimeConfig();
    const modalState = useModalState();
    const systemConfig = useSystemStore();

    const user = computed<UserType | null>(() => data.value || null);
    const isMenuOpen = ref<boolean>(false);
    const nav = ref<HTMLElement | null>(null);
    const navItems = ref<HTMLElement | null>(null);
    const activeNode = ref<NavigationNode | null>(null);

    const onModalClose = () => {
        modalState.value.active = null;
        $event('modal:closed');
    };

    const onModalResult = (result: any) => {
        $event('modal:result', result);
    };

    const navigation: NavigationGroup[] = [
        {
            id: 'staff',
            label: 'Staff',
            nodes: [
                {
                    id: 'dashboard',
                    label: 'Dashboard',
                    path: '/',
                    icon: [ 'fad', 'grid' ],
                },
                {
                    id: 'shifts',
                    label: 'Shifts',
                    path: '/shifts',
                    icon: [ 'fad', 'calendar-days' ],
                },
                {
                    id: 'files',
                    label: 'Files',
                    path: '/files',
                    icon: [ 'fad', 'files' ],
                },
            ],
        },
        {
            id: 'staff-cases',
            label: 'Cases',
            nodes: [
                {
                    id: 'create-cases',
                    label: 'Create Case',
                    path: '/cases',
                    icon: [ 'fad', 'file-circle-plus' ],
                    hasPermission: user?.value?.permissions.includes('cases.add_case'),
                },
                {
                    id: 'my-cases',
                    label: 'My Cases',
                    path: '/cases/mine',
                    icon: [ 'fad', 'stethoscope' ],
                    hasPermission: user?.value?.permissions.includes('cases.add_case'),
                },
                {
                    id: 'audit-cases',
                    label: 'Audit Cases',
                    path: '/cases/audit',
                    icon: [ 'fad', 'badge-check' ],
                    hasPermission: user?.value?.permissions.includes('cases.audit_case'),
                },
            ],
        },
        {
            id: 'events',
            label: 'Events',
            nodes: [
                {
                    id: 'events',
                    label: 'Events',
                    path: '/admin/events',
                    icon: [ 'fad', 'tent-double-peak' ],
                    hasPermission: user?.value?.permissions.includes('events.view_event'),
                },
                {
                    id: 'fill-shifts',
                    label: 'Fill Shifts',
                    path: '/admin/fill-shifts',
                    icon: [ 'fad', 'calendar-check' ],
                    hasPermission: user?.value?.permissions.includes('events.view_eoi'),
                },
                {
                    id: 'all-eois',
                    label: 'EOIs',
                    path: '/admin/events/eois',
                    icon: [ 'fad', 'face-thinking' ],
                    hasPermission: user?.value?.permissions.includes('events.view_eoi'),
                },
                {
                    id: 'timesheets',
                    label: 'Timesheets',
                    path: '/admin/timesheets',
                    icon: [ 'fad', 'clock' ],
                    hasPermission: user?.value?.permissions.includes('cases.view_timesheet'),
                },
            ],
        },
        {
            id: 'admin',
            label: 'Admin',
            nodes: [
                {
                    id: 'users',
                    label: 'Users',
                    path: '/admin/users',
                    icon: [ 'fad', 'users' ],
                    hasPermission: user?.value?.isSuperuser,
                },
                {
                    id: 'sms-email',
                    label: 'SMS/Email',
                    path: '/admin/sms-email',
                    icon: [ 'fad', 'comments' ],
                    hasPermission: user?.value?.isSuperuser,
                },
                {
                    id: 'qualification-documents',
                    label: 'Qualification Documents',
                    path: '/admin/qualifications/documents',
                    icon: [ 'fad', 'id-card' ],
                    hasPermission: user?.value?.permissions.includes('core.view_qualification_document'),
                },
                {
                    id: 'settings',
                    label: 'Settings',
                    icon: [ 'fad', 'cog' ],
                    children: [
                        {
                            id: 'staff',
                            label: 'Staff',
                            nodes: [
                                {
                                    id: 'skillsets',
                                    label: 'Skillsets',
                                    path: '/admin/skillsets',
                                    icon: [ 'fad', 'graduation-cap' ],
                                    hasPermission: user?.value?.permissions.includes('core.view_skillset'),
                                },
                                {
                                    id: 'accreditations',
                                    label: 'Accreditations',
                                    path: '/admin/accreditations',
                                    icon: [ 'fad', 'badge-check' ],
                                    hasPermission: user?.value?.permissions.includes('core.view_accreditation'),
                                },
                                {
                                    id: 'qualifications',
                                    label: 'Qualifications',
                                    path: '/admin/qualifications',
                                    icon: [ 'fad', 'medal' ],
                                    hasPermission: user?.value?.permissions.includes('core.view_qualification'),
                                },
                                {
                                    id: 'regions',
                                    label: 'Regions',
                                    path: '/admin/regions',
                                    icon: [ 'fad', 'map' ],
                                    hasPermission: user?.value?.permissions.includes('core.view_region'),
                                },
                            ],
                        },
                        {
                            id: 'events',
                            label: 'Events',
                            nodes: [
                                {
                                    id: 'event-kinds',
                                    label: 'Event Types',
                                    path: '/admin/events/types',
                                    icon: [ 'fad', 'filters' ],
                                    hasPermission: user?.value?.permissions.includes('events.view_eventkind'),
                                },
                                {
                                    id: 'clients',
                                    label: 'Clients',
                                    path: '/admin/clients',
                                    icon: [ 'fad', 'people-simple' ],
                                    hasPermission: user?.value?.permissions.includes('events.view_client'),
                                },
                                {
                                    id: 'hospitals',
                                    label: 'Hospitals',
                                    path: '/admin/hospitals',
                                    icon: [ 'fad', 'hospital' ],
                                    hasPermission: user?.value?.permissions.includes('events.view_hospital'),
                                },
                                {
                                    id: 'resources',
                                    label: 'Resources',
                                    path: '/admin/resources',
                                    icon: [ 'fad', 'car' ],
                                    hasPermission: user?.value?.permissions.includes('events.view_resource'),
                                },
                                {
                                    id: 'templates',
                                    label: 'Templates',
                                    path: '/admin/templates',
                                    icon: [ 'fad', 'code' ],
                                    hasPermission: user?.value?.permissions.includes('core.view_skillset'),
                                },
                            ],
                        },
                        {
                            id: 'cases',
                            label: 'Cases',
                            nodes: [
                                {
                                    id: 'advice-templates',
                                    label: 'Advice Templates',
                                    path: '/admin/cases/advice',
                                    icon: [ 'fad', 'code' ],
                                    hasPermission: user?.value?.permissions.includes('cases.view_advicetemplate'),
                                },
                                {
                                    id: 'case-categories',
                                    label: 'Case Categories',
                                    path: '/admin/cases/categories',
                                    icon: [ 'fad', 'list' ],
                                    hasPermission: user?.value?.permissions.includes('cases.view_casecategory'),
                                },
                                {
                                    id: 'case-category-sets',
                                    label: 'Case Category Sets',
                                    path: '/admin/cases/categories/sets',
                                    icon: [ 'fad', 'list-check' ],
                                    hasPermission: user?.value?.permissions.includes('cases.view_casecategoryset'),
                                },
                                {
                                    id: 'consumables',
                                    label: 'Consumables',
                                    path: '/admin/cases/consumables',
                                    icon: [ 'fad', 'bandage' ],
                                    hasPermission: user?.value?.permissions.includes('cases.view_consumable'),
                                },
                                {
                                    id: 'drugs',
                                    label: 'Drugs',
                                    path: '/admin/cases/drugs',
                                    icon: [ 'fad', 'pills' ],
                                    hasPermission: user?.value?.permissions.includes('cases.view_drug'),
                                },
                            ],
                        },
                        {
                            id: 'org',
                            label: 'Organisation',
                            nodes: [
                                {
                                    id: 'slack',
                                    label: 'Slack',
                                    path: '/admin/slack',
                                    icon: [ 'fab', 'slack' ],
                                    hasPermission: user?.value?.isSuperuser,
                                },
                                {
                                    id: 'org',
                                    label: 'Organisation',
                                    path: '/admin/organisation',
                                    icon: [ 'fad', 'building' ],
                                    hasPermission: user?.value?.isSuperuser,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ];

    const onSelectNode = async(node: NavigationNode) => {
        activeNode.value = node;

        await nextTick();

        navItems.value!.scrollTo({
            left: navItems.value!.scrollLeft + navItems.value!.getBoundingClientRect().width,
            behavior: 'auto',
        });
    };

    const onBack = () => {
        navItems.value!.scrollTo({
            left: 0,
            behavior: 'auto',
        });
    };

    const onLogout = async() => {
        await signOut();
    };

    const onScroll = () => {
        if (navItems.value!.scrollLeft === 0) {
            activeNode.value = null;
        }
    };

    const onToggleDarkMode = () => {
        systemConfig.darkMode = !systemConfig.darkMode;
    };

    watch(isMenuOpen, newValue => {
        if (newValue) {
            disableBodyScroll(nav.value!, {
                reserveScrollBarGap: true,
            });
        } else {
            enableBodyScroll(nav.value!);
        }
    });

    onMounted(() => {
        navItems.value?.addEventListener('scroll', onScroll);
    });

    onBeforeUnmount(() => {
        navItems.value?.removeEventListener('scroll', onScroll);
    });

    $listen('menu:opened', () => {
        isMenuOpen.value = true;
    });

    watch(() => route.path, () => {
        isMenuOpen.value = false;
    });
</script>

<style lang="postcss">
    html,
    body {
        @apply bg-body text-gray-900 min-h-screen;
        @apply dark:text-gray-100;
        @apply dark:bg-gray-900;

        --nav-width: clamp(18rem, 20vw, 24rem);
    }

    .default-layout {
        --nav-width: clamp(18rem, 20vw, 24rem);

        &__wrapper {
            @apply pl-0;

            @screen lg {
                padding-left: var(--nav-width);
            }
        }

        &__window {
            @apply flex flex-col min-h-screen;
        }
    }

    .nav {
        @apply fixed inset-y-0 bg-gray-100 z-[999] overflow-auto;
        @apply dark:bg-gray-800;

        @apply transform -translate-x-full scale-90 pointer-events-none;
        @apply transition-[transform,box-shadow] duration-300 ease-in-out;
        @apply motion-reduce:transition-none;

        width: calc(100vw - theme('spacing.20'));

        @screen lg {
            @apply left-auto translate-x-0 scale-100 pointer-events-auto transition-none;
            width: var(--nav-width);
        }

        &--open {
            @apply translate-x-0 scale-100 pointer-events-auto shadow-2xl;
        }

        &__group {
            & + & {
                @apply mt-8;
            }
        }

        &__items {
            @apply flex overflow-x-auto;
            scroll-snap-type: x mandatory;
            scroll-behavior: smooth;
            scrollbar-width: none;
            min-height: calc(100% - 161px);

            &::-webkit-scrollbar {
                @apply hidden bg-transparent w-0;
            }

            > div {
                 @apply flex flex-col flex-shrink-0 w-full px-4 pt-2 pb-2;

                scroll-snap-align: start;
            }
        }

        &__link {
            @apply flex items-center text-black rounded-md px-4 py-2 w-full text-left;
            @apply dark:text-white;

            &--active {
                @apply bg-gray-200;
                @apply dark:bg-gray-700;
            }

            &--error {
                @apply !text-red-600;
                @apply dark:!text-red-400;
            }

            &:hover {
                @apply text-primary-800 dark:text-primary-200;
            }
        }

        &__icon {
            @apply block w-4 h-4 mr-2;
        }
    }

    .nav-overlay {
        @apply block lg:hidden fixed inset-0 bg-black/50 z-[150];
    }

    * {
        &::-webkit-scrollbar {
            @apply bg-transparent w-2 h-3;
        }

        &::-webkit-scrollbar-corner {
            @apply bg-transparent;
        }

        &::-webkit-scrollbar-track {
            @apply bg-gray-200 dark:bg-gray-800;
        }

        &::-webkit-scrollbar-thumb {
            @apply bg-gray-400 rounded-full;
            @apply dark:bg-gray-600;
        }
    }
</style>
