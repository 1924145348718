<template>
    <modal
        :title="props.title"
        @close="onClose"
    >
        <section class="pb-4 py-6 -mt-6 lg:-mt-8 sticky -top-6 lg:-top-8 bg-body dark:bg-gray-900">
            <form-row no-collapse>
                <form-field shrink>
                    <dropdown
                        :icon="['fad', 'filter']"
                        :teleport="false"
                        fixed
                    >
                        <form-row>
                            <form-checkbox-list
                                id="filter-skillsets"
                                v-model="filterSkillsets"
                                :values="skillsets"
                                label="Skillsets"
                            />
                        </form-row>
                    </dropdown>
                </form-field>

                <form-input
                    id="filter-search"
                    v-model="filterSearch"
                    type="text"
                    placeholder="Search"
                />
            </form-row>

            <form-row v-if="hiddenUsers > 0">
                <form-field>
                    <p class="text-sm font-semibold">{{ hiddenUsers }} {{ hiddenUsers > 1 ? 'users' : 'user' }} hidden by filters.</p>
                </form-field>
            </form-row>
        </section>

        <ul>
            <li
                v-for="{ node } in users?.edges"
                :key="node.id"
                class="mb-2 last:mb-0"
            >
                <button
                    type="button"
                    class="block w-full text-left p-4 rounded-lg bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700 disabled:opacity-50 disabled:pointer-events-none"
                    :disabled="disabledIds.includes(node.id)"
                    @click.prevent="emits('result', node)"
                >
                    <span class="block">{{ node.lastName }}, {{ node.firstName }}</span>
                    <span class="block text-xs">{{ node.skillset?.name }}</span>
                </button>
            </li>
        </ul>

        <load-more
            v-if="hasMore"
            :loading="loading"
            class="text-center mt-4"
            @load-more="loadMoreResults"
        />
    </modal>
</template>

<script setup lang="ts">
    import { createListFactory } from '~/factories/list';
    import { library } from '@fortawesome/fontawesome-svg-core';
    import { faFilter } from '@fortawesome/pro-duotone-svg-icons';

    import type { UserFilter, UserType, UserTypeConnection } from '~/generated/types';
    import {
        GET_USERS_QUERY,
        type GetUsersData,
        type GetUsersVariables,
    } from '~/graphql/queries/users';

    library.add(faFilter);

    export interface Props {
        title: string;
        disabledIds?: string[];
    }

    const props = withDefaults(defineProps<Props>(), {
        disabledIds: () => ([]),
    });

    const emits = defineEmits<{
        close: [void];
        result: [UserType];
    }>();

    const filterSkillsets = ref<string[]>([]);
    const filterSearch = ref<string>('');

    const onClose = () => emits('close');

    const skillsets = (await getSkillsets()).map(skillset => ({
        id: skillset.id,
        value: skillset.id,
        label: skillset.name,
    }));

    const variables = computed<GetUsersVariables>(() => {
        let filter: UserFilter = {};

        if (filterSkillsets.value.length > 0) {
            filter.skillset = {
                inList: filterSkillsets.value,
            };
        }

        if (filterSearch.value.trim() !== '') {
            const userFilter = {
                fullName: {
                    iContains: filterSearch.value.trim(),
                },
                OR: {
                    firstName: {
                        iContains: filterSearch.value.trim(),
                    },
                    OR: {
                        lastName: {
                            iContains: filterSearch.value.trim(),
                        },
                    },
                },
            };

            if (filterSkillsets.value.length > 0) {
                filter = {
                    ...filter,
                    AND: userFilter,
                };
            } else {
                filter = userFilter;
            }
        }

        return {
            first: PAGE_SIZE,
            filter,
        };
    });

    const { data: users, result, loading, loadMoreResults, hasMore } = createListFactory<GetUsersData, UserTypeConnection, GetUsersVariables>(GET_USERS_QUERY, variables, result => {
        return result?.users;
    });

    const hiddenUsers = computed(() => (result.value?.allUsers.totalCount || 0) - (result.value?.users.totalCount || 0));
</script>
