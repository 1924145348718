<template>
    <modal
        title="Create Client"
        @close="onClose"
    >
        <form
            novalidate
            @submit.prevent="onSubmit"
        >
            <form-row>
                <form-input
                    id="client-name"
                    v-model="name"
                    label="Name"
                    type="text"
                    v-bind="nameAttrs"
                    :icon="['fad', 'user']"
                    :errors="errors.name"
                    autocomplete="off"
                />
            </form-row>

            <form-address />

            <form-row>
                <form-repeater
                    label="Contacts"
                    add-new-label="Add contact"
                    path="contacts"
                    :errors="errors.contacts"
                    :default-object="(value: any[]) => ({
                        firstName: '',
                        lastName: '',
                        email: '',
                        phone: '',
                        primary: value.length === 0,
                    })"
                >
                    <template #item="{ fields, field, index, remove }">
                        <form-row>
                            <form-input
                                :id="`client-contact-${index}-first-name`"
                                v-model="(field.value as Record<string, any>).firstName"
                                :name="`contacts[${index}].firstName`"
                                label="First Name"
                                type="text"
                                autocomplete="given-name"
                                :errors="getErrors(`contacts[${index}].firstName`)"
                            />

                            <form-input
                                :id="`client-contact-${index}-last-name`"
                                v-model="(field.value as Record<string, any>).lastName"
                                :name="`contacts[${index}].lastName`"
                                label="Last Name"
                                type="text"
                                autocomplete="family-name"
                                :errors="getErrors(`contacts[${index}].lastName`)"
                            />
                        </form-row>

                        <form-row>
                            <form-input
                                :id="`client-contact-${index}-email`"
                                v-model="(field.value as Record<string, any>).email"
                                :name="`contacts[${index}].email`"
                                label="Email"
                                type="email"
                                autocomplete="email"
                                :errors="getErrors(`contacts[${index}].email`)"
                            />

                            <form-input
                                :id="`client-contact-${index}-phone`"
                                v-model="(field.value as Record<string, any>).phone"
                                :name="`contacts[${index}].phone`"
                                label="Phone"
                                type="tel"
                                autocomplete="tel-national"
                                :errors="getErrors(`contacts[${index}].phone`)"
                            />
                        </form-row>

                        <form-row>
                            <form-checkbox
                                :id="`client-contact-${index}-primary`"
                                v-model="(field.value as Record<string, any>).primary"
                                :name="`contacts[${index}].primary`"
                                label="This is the primary contact"
                                @change="onPrimaryChange(index, (field.value as Record<string, any>).primary, fields)"
                            />

                            <form-field shrink>
                                <styled-button
                                    :icon="['fad', 'trash-alt']"
                                    size="tiny"
                                    color="danger"
                                    @click.prevent="remove(index)"
                                >
                                    Remove Contact
                                </styled-button>
                            </form-field>
                        </form-row>
                    </template>
                </form-repeater>
            </form-row>
        </form>

        <template #footer>
            <styled-button
                :icon="['fad', 'save']"
                :loading="loading"
                :disabled="!meta.valid"
                @click.prevent="onSubmit"
            >
                Create Client
            </styled-button>
        </template>
    </modal>
</template>

<script setup lang="ts">
    import { useForm, type FieldEntry } from 'vee-validate';
    import { toTypedSchema } from '@vee-validate/yup';
    import * as yup from 'yup';
    import { library } from '@fortawesome/fontawesome-svg-core';
    import { faSave, faTrashAlt } from '@fortawesome/pro-duotone-svg-icons';

    import type { ClientType, OperationInfo } from '~/generated/types';
    import {
        CREATE_CLIENT_MUTATION,
        type CreateClientData,
        type CreateClientVariables,
    } from '~/graphql/mutations/clients';

    library.add(faSave, faTrashAlt);

    export interface Props {
        defaultName?: string;
    }

    const props = withDefaults(defineProps<Props>(), {
        defaultName: '',
    });

    const emits = defineEmits<{
        close: [void];
        result: [{ id: string, text: string }];
    }>();

    const onClose = () => emits('close');

    const { meta, errors, defineField, handleSubmit } = useForm({
        validationSchema: toTypedSchema(
            yup.object({
                name: yup.string().max(255).required().label('Name').default(props.defaultName),
                address: addressSchema(),
                contacts: yup.array(
                    yup.object().shape({
                        firstName: yup.string().max(255).required().label('First Name'),
                        lastName: yup.string().max(255).required().label('Last Name'),
                        email: yup.string().email().max(255).label('Email').default(''),
                        phone: yup.string().max(255).label('Phone').default(''),
                        primary: yup.boolean().label('Primary').default(false),
                    }),
                )
                .min(1, 'At least one contact is required')
                .test(
                    'one-primary',
                    'One contact must be marked as primary',
                    contacts => contacts?.filter(contact => contact.primary).length === 1
                )
                .default([]),
            }),
        ),
    });

    provide('defineField', defineField);

    const getErrors = (key: string) => {
        // @ts-ignore-next-line
        return errors.value[key];
    };

    const [ name, nameAttrs ] = defineField('name');

    const onPrimaryChange = (index: number, value: boolean, fields: FieldEntry[]) => {
        if (!value) {
            return;
        }

        fields.forEach((contact, i) => {
            if (i === index) {
                return;
            }

            // @ts-ignore-next-line
            contact.value.primary = false;
        });
    };

    const { mutate, loading } = useMutation<CreateClientData, CreateClientVariables>(CREATE_CLIENT_MUTATION, {
        update(cache) {
            cache.evict({
                fieldName: 'clients',
            });
        },
    });

    const onSubmit = handleSubmit(async values => {
        if (loading.value) {
            return;
        }

        const response = await mutate({
            data: {
                ...values,

                // @ts-expect-error
                address: values.address?.raw ? values.address : undefined,
            },
        });

        const data = response?.data?.createClient as ClientType;
        const info = response?.data?.createClient as OperationInfo;

        if (!data) {
            console.log(info);
            return;
        }

        emits('result', {
            id: data.id,
            text: data.name,
        });
    });
</script>
