import type { NotificationCreatedType } from '~/generated/types';

export type NotificationCreatedData = {
    notificationCreated: NotificationCreatedType;
}

export const NOTIFICATION_CREATED_SUBSCRIPTION = gql`
    subscription NotificationCreated {
        notificationCreated {
            id
            notification {
                id
                url
                message
                read
                createdAt
            }
            totalUnreadCount
        }
    }
`;
