import type { NotificationTypeConnection } from '~/generated/types';

export type GetNotificationsData = {
    notifications: NotificationTypeConnection;
    unread: {
        totalCount: number;
    }
}

export type GetNotificationsVariables = {
    first: number;
    after?: string;
};

export const GET_NOTIFICATIONS_QUERY = gql`
    query GetNotifications($first: Int, $after: String) {
        notifications(first: $first, after: $after) {
            edges {
                node {
                    id
                    url
                    message
                    read
                    createdAt
                }
            }

            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }

            totalCount
        }
        
        unread: notifications(filters: { read: { exact: false } }) {
            totalCount
        }
    }
`;
