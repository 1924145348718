<template>
    <dropdown
        side="left"
        content-class="!min-w-64 max-h-96 overflow-auto"
        :padding="false"
        @on-open="onOpen"
        @on-close="onClose"
    >
        <template #button="{ toggle, isOpen }">
            <button
                type="button"
                class="relative text-xl p-3 leading-none bg-gray-200 dark:bg-gray-900 rounded-lg min-w-[44.5px]"
                :class="{
                    'ring-2 ring-primary-600': isOpen,
                }"
                data-toggle-dropdown
                @click.prevent="toggle"
            >
                <span class="sr-only">Notifications</span>
                <font-awesome-icon :icon="['fad', 'bell']" />

                <span
                    v-if="(result?.unread.totalCount ?? 0) > 0"
                    class="absolute block w-3 h-3 top-0 right-0 -mt-1 -mr-1 bg-red-500 rounded-full"
                />
            </button>
        </template>

        <ul class="whitespace-normal text-xs">
            <li
                v-for="notification in notificationData?.edges ?? []"
                :key="notification.node.id"
                ref="notifications"
                :data-id="notification.node.id"
            >
                <nuxt-link
                    v-if="notification.node.url"
                    class="block pl-4 pr-3 py-2 border-b border-black/10 dark:border-white/10 hover:bg-black/5 dark:hover:bg-white/5"
                    :to="notification.node.url"
                >
                    <div class="flex items-center">
                        <span>{{ notification.node.message }}</span>

                        <span
                            class="block flex-shrink-0 w-2 h-2 ml-2 bg-primary-500 rounded-full transition-opacity duration-150 ease-in-out"
                            :class="{ 'opacity-0': notification.node.read }"
                        />
                    </div>

                    <div class="text-xxs mt-0.5 opacity-50">{{ friendlyFormatDate(notification.node.createdAt) }}</div>
                </nuxt-link>

                <div
                    v-else
                    class="pl-4 pr-3 py-2 border-b border-black/10 dark:border-white/10 hover:bg-black/5 dark:hover:bg-white/5"
                >
                    <div class="flex items-center">
                        <span>{{ notification.node.message }}</span>

                        <span
                            class="block flex-shrink-0 w-2 h-2 ml-2 bg-primary-500 rounded-full transition-opacity duration-150 ease-in-out"
                            :class="{ 'opacity-0': notification.node.read }"
                        />
                    </div>

                    <div class="text-xxs mt-0.5 opacity-50">{{ friendlyFormatDate(notification.node.createdAt) }}</div>
                </div>
            </li>

            <li v-if="(notificationData?.edges.length ?? 0) > 0">
                <load-more
                    v-if="hasMore"
                    :loading="loading"
                    class="text-center mt-4"
                    @load-more="loadMoreResults"
                />
            </li>

            <li
                v-else
                class="p-4"
            >
                <p class="text-sm">You have no notifications.</p>
            </li>
        </ul>
    </dropdown>
</template>

<script setup lang="ts">
    import { library } from '@fortawesome/fontawesome-svg-core';
    import { faBell } from '@fortawesome/pro-duotone-svg-icons';

    import sound from '~/assets/sounds/notification.mp3';

    import { createListFactory } from '~/factories/list';
    import type { NotificationType, NotificationTypeConnection, UserType } from '~/generated/types';
    import {
        GET_NOTIFICATIONS_QUERY,
        type GetNotificationsData,
        type GetNotificationsVariables,
    } from '~/graphql/queries/notification';
    import {
        READ_NOTIFICATION_MUTATION,
        type ReadNotificationData,
        type ReadNotificationVariables,
    } from '~/graphql/mutations/notifications';
    import {
        NOTIFICATION_CREATED_SUBSCRIPTION,
        type NotificationCreatedData,
    } from '~/graphql/subscriptions/notifications';

    library.add(faBell);

    const observer = ref<IntersectionObserver | null>(null);
    const notifications = ref<HTMLElement[]>([]);
    const pendingRead = ref<string[]>([]);
    const timeout = ref<ReturnType<typeof setTimeout> | null>(null);
    const notificationSound = import.meta.client ? new Audio(sound) : null;

    const variables = computed<GetNotificationsVariables>(() => {
        return {
            first: PAGE_SIZE,
        };
    });

    const { data: notificationData, result, loading, loadMoreResults, hasMore } = createListFactory<GetNotificationsData, NotificationTypeConnection, GetNotificationsVariables>(GET_NOTIFICATIONS_QUERY, variables, result => {
        return result?.notifications;
    });

    const { onResult } = useSubscription<NotificationCreatedData>(NOTIFICATION_CREATED_SUBSCRIPTION);

    onResult(async({ data }, { client }) => {
        if (!data) {
            return;
        }

        const { notificationCreated } = data;

        client.cache.updateQuery<GetNotificationsData, GetNotificationsVariables>({
            query: GET_NOTIFICATIONS_QUERY,
            variables: variables.value,
        }, data => {
            if (!data) {
                return;
            }

            return {
                ...data,
                unread: {
                    ...data.unread,
                    totalCount: notificationCreated.totalUnreadCount,
                },
                notifications: {
                    ...data.notifications,
                    edges: [
                        {
                            cursor: notificationCreated.notification.id,
                            node: notificationCreated.notification,
                            __typename: 'NotificationTypeEdge',
                        },
                        ...data.notifications.edges,
                    ],
                },
            };
        });

        await notificationSound?.play();
    });

    const { mutate, loading: loadingMutate } = useMutation<ReadNotificationData, ReadNotificationVariables>(READ_NOTIFICATION_MUTATION, {
        update(cache, { data }) {
            if (!data?.readNotifications) {
                return;
            }

            if (!result.value) {
                return;
            }

            cache.writeQuery({
                query: GET_NOTIFICATIONS_QUERY,
                variables: variables.value,
                data: {
                    ...result.value,
                    unread: {
                        ...result.value.unread,
                        totalCount: data.readNotifications.totalUnreadCount,
                    },
                },
            });

            for (const notification of data.readNotifications.notifications) {
                const id = cache.identify(notification);

                cache.modify({
                    id,
                    fields: {
                        read() {
                            return notification.read;
                        },
                    },
                });
            }
        },
    });

    const onOpen = async(content: HTMLElement) => {
        let options = {
            root: content,
            rootMargin: '0px',
            threshold: 0.8,
        };

        observer.value = new IntersectionObserver((entries, observer) => {
            for (const entry of entries) {
                if (entry.isIntersecting) {
                    pendingRead.value.push((entry.target as HTMLElement).dataset.id ?? '');

                    if (timeout.value) {
                        clearTimeout(timeout.value);
                    }

                    timeout.value = setTimeout(() => {
                        onMarkAsRead();
                    }, 1000);
                }
            }
        }, options);

        for (const item of notifications.value) {
            observer.value.observe(item);
        }
    };

    watch(notificationData, async() => {
        await nextTick();

        for (const item of notifications.value) {
            observer.value?.observe(item);
        }
    });

    const onClose = () => {
        observer.value?.disconnect();
    };

    const onMarkAsRead = async() => {
        if (loadingMutate.value) {
            timeout.value = setTimeout(() => {
                onMarkAsRead();
            }, 1000);

            return;
        }

        const notifications: NotificationType[] = pendingRead.value.map(id => {
            return notificationData.value?.edges?.find(edge => edge.node.id === id)?.node;
        }).filter(node => node && !node.read) as NotificationType[];

        if (notifications.length === 0) {
            return;
        }

        await mutate({
            notificationIds: notifications.map(node => node.id),
        });

        pendingRead.value = [];
    };
</script>
