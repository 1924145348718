<template>
    <modal
        :title="props.title"
        @close="onClose"
    >
        <form
            novalidate
            @submit.prevent="onSubmit"
        >
            <form-row v-if="props.text">
                <form-field>
                    <p>{{ props.text }}</p>
                </form-field>
            </form-row>

            <form-row>
                <form-input
                    v-if="!props.multiline"
                    id="text-input"
                    ref="inputElement"
                    v-model="value"
                    :label="props.inputLabel"
                    :type="props.type"
                    v-bind="valueAttrs"
                    :icon="['fad', 'notes']"
                    :errors="errors.value"
                    autofocus
                    required
                    autocomplete="off"
                />

                <form-growing-textarea
                    v-else
                    id="text-input"
                    v-model="value"
                    :label="props.inputLabel"
                    v-bind="valueAttrs"
                    :icon="['fad', 'notes']"
                    :errors="errors.value"
                    autofocus
                    required
                />
            </form-row>
        </form>

        <template #footer>
            <styled-button
                :icon="['fad', 'fa-check']"
                :disabled="!meta.valid"
                @click.prevent="onSubmit"
            >
                {{ props.acceptLabel }}
            </styled-button>
        </template>
    </modal>
</template>

<script setup lang="ts">
    import { useForm } from 'vee-validate';
    import { toTypedSchema } from '@vee-validate/yup';
    import * as yup from 'yup';
    import { library } from '@fortawesome/fontawesome-svg-core';
    import { faNotes, faCheck } from '@fortawesome/pro-duotone-svg-icons';

    library.add(faNotes, faCheck);

    export interface Props {
        title: string;
        text?: string;
        type?: string;
        defaultValue?: string;
        inputLabel: string;
        acceptLabel: string;
        multiline?: boolean;
        autofocus?: boolean;
    }

    const props = withDefaults(defineProps<Props>(), {
        text: undefined,
        defaultValue: undefined,
        type: 'text',
        multiline: false,
        autofocus: false,
    });

    const emits = defineEmits<{
        close: [void];
        result: [string];
    }>();

    const onClose = () => emits('close');

    const { meta, errors, defineField, handleSubmit } = useForm({
        validationSchema: toTypedSchema(
            yup.object({
                value: yup.string()
                    .max(255)
                    .required()
                    .label(props.inputLabel)
                    .default(props.defaultValue),
            }),
        ),
    });

    const [ value, valueAttrs ] = defineField('value');

    const onSubmit = handleSubmit(async values => {
        emits('result', values.value);
        onClose();
    });
</script>
